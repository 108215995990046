.container-hero {
  position: relative;
  width: 100%;
  height: 70vh;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-viewbox {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.container-slides {
  position: relative;
  display: flex;
  height: 100%;
  transition: all 0.5s var(--anim);
}

.slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8vw;
}

.slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  filter: brightness(50%);
}

.container-slide-text {
  width: 50%;
  z-index: 1;
  color: var(--font-color3);
}

.container-slide-text h3 {
  font-size: clamp(2rem, 4vw, 4rem);
  text-transform: uppercase;
}

.container-slide-text p {
  padding: 25px 0;
  font-size: clamp(1.4rem, 1.2vw, 2rem);
  font-weight: 300;
}

.slide-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--color2);
  cursor: pointer;
  z-index: 1;
}

.slide-btn:before,
.slide-btn:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: var(--font-color1);
}

.slide-btn:before {
  transform: rotate(45deg) translateY(-5px);
}

.slide-btn:after {
  transform: rotate(-45deg) translateY(5px);
}

.slide-btn-left {
  left: 0;
  transform: rotate(180deg);
}

.slide-btn-right {
  right: 0;
}

@media screen and (max-width: 850px) {
  .container-slide-text {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .slide-btn {
    width: 40px;
    height: 40px;
    bottom: 20px;
  }
}
