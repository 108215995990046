.container-testimonial {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-testimonial-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  font-size: max(1.2vw, 1.2rem);
}

.testimonial-slide-viewbox {
  position: relative;
  height: 100%;
  width: calc(100% - 100px);
  overflow: hidden;
}

.container-testimonial-slides {
  position: relative;
  display: flex;
  height: 100%;
  transition: all 0.5s var(--anim);
}

.testimonial-slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw 6vw;
}

.testimonial-slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.container-testimonial-slide-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  color: var(--font-color1);
}

.container-testimonial-slide-text h5 {
  font-size: max(1vw, 0.8rem);
  text-transform: uppercase;
}

.container-testimonial-slide-text p {
  padding: 25px 0;
  font-size: max(1.2vw, 0.9rem);
}

.testimonial-slide-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: var(--color2);
  cursor: pointer;
  z-index: 1;
}

.testimonial-slide-btn:before,
.testimonial-slide-btn:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: var(--font-color1);
}

.testimonial-slide-btn:before {
  transform: rotate(45deg) translateY(-5px);
}

.testimonial-slide-btn:after {
  transform: rotate(-45deg) translateY(5px);
}

.testimonial-slide-btn-left {
  left: 0;
  transform: rotate(180deg);
}

.testimonial-slide-btn-right {
  right: 0;
}

@media screen and (max-width: 850px) {
  .container-testimonial-slide-text {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .testimonial-slide-btn {
    width: 40px;
    height: 40px;
  }
}
