.container-story-page {
  width: 100%;
  height: auto;
  padding-bottom: 2vw;
}

.container-story-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}

.container-story-hero-image {
  width: 100%;
  padding-top: 60%;
  position: relative;
}

.story-hero-image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.story-hero-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-story-text {
  background-color: var(--color1);
  color: var(--font-color3);
  padding: 4vw;
}

.container-story-text p {
  padding-bottom: 2vw;
  font-size: max(14px, 1.05vw);
}
