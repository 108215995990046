.container-products {
  width: 100%;
  height: auto;
}

.products-search {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.products-search input {
  padding: 6px;
  font-size: max(14px, 1vw);
  transform: translateX(-10px);
  border: 2px solid var(--font-color1);
}

.products-search svg {
  transform: scale(0.8) translateX(-12px);
}

.container-product-option {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.products-option {
  position: relative;
  width: 90%;
  padding-top: 50%;
  margin-bottom: 40px;
  min-height: 550px;
  box-shadow: 0px 1.5px 2.2px rgba(0, 0, 0, 0.02),
    0px 3.5px 5.3px rgba(0, 0, 0, 0.028), 0px 6.6px 10px rgba(0, 0, 0, 0.035),
    0px 11.8px 17.9px rgba(0, 0, 0, 0.042),
    0px 22.1px 33.4px rgba(0, 0, 0, 0.05), 0px 53px 80px rgba(0, 0, 0, 0.07);
  background-color: var(--color3);
}

.products-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.products-option:nth-of-type(even) {
  background-color: var(--color1);
  color: var(--font-color3);
}

.products-option-link {
  grid-row: 1 / 6;
  grid-column: 1 / 2;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding: 20px;
}

.products-option-link div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-row: 2 / 4;
}

.products-option-link div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  grid-row: 4 / 6;
}

.products-option-link h2 {
  font-size: max(18px, 1.6vw);
  padding-bottom: 15px;
  font-style: italic;
}

.products-option-link p {
  font-size: max(14px, 1vw);
  padding-bottom: 15px;
  font-weight: 200;
}

.products-option-img {
  grid-row: 1 / 5;
  grid-column: 2 / 6;
}

.products-option-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.products-option-text {
  grid-row: 5 / 6;
  grid-column: 2 / 6;
  padding: 20px;
}

.products-option-text h3 {
  padding-bottom: 10px;
  font-size: max(16px, 1vw);
}

.products-option-text p {
  font-weight: 300;
  font-size: max(12px, 0.8vw);
}

@media screen and (max-width: 900px) {
  .products-option-img {
    grid-row: 1 / 5;
    grid-column: 1 / 6;
  }
  .products-option-text {
    display: none;
  }
  .products-option-link {
    grid-row: 5 / 6;
    grid-column: 1 / 6;
    display: flex;
    padding: 40px;
  }
  .products-option-link div {
    flex: 1 1;
  }
  .products-option-img img {
    object-position: center;
  }
  .products-option {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .products-option-link {
    flex-direction: column;
  }

  .products-option-link div:nth-of-type(1) {
    padding-bottom: 20px;
  }
}
