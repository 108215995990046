* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --anim: cubic-bezier(0.39, 0.575, 0.565, 1);
  --shadow: #d6d6d6 1px 0px 2px;
  --font-color1: black;
  --font-color2: rgb(71, 71, 71);
  --font-color3: white;
  --color1: rgb(12, 12, 12);
  --color2: white;
  --color3: #e9e9e9;
  --color4: #dab870;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  margin-top: 100px;
  width: 100vw;
  padding: 0 10vw;
  height: auto;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--color1);
}

@media screen and (max-width: 450px) {
  main {
    padding: 0 5vw;
  }
}
