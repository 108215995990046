.container-media {
  width: 100%;
  height: auto;
  padding: 50px 0;
}

.container-media h3 {
  font-size: max(1.2vw, 1.2rem);
}

.container-media p {
  text-align: center;
  font-size: max(1.2vw, 0.8rem);
}

.container-media-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}

.container-media-wrapper {
  display: flex;
  flex-direction: row;
}

.container-media-links {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
}

.container-media-links li {
  position: relative;
  flex: 1 1 50%;
  min-width: 150px;
  padding-top: 50%;
  overflow: hidden;
  cursor: pointer;
}

.container-media-links li a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.media-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  transition: all 0.3s var(--anim);
}

.media-text {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 10%;
  pointer-events: none;
  color: var(--font-color3);
  opacity: 0;
  transition: all 0.3s var(--anim);
}

.media-text p {
  font-size: max(1vw, 0.6rem);
}

.container-media-links li a:hover > .media-img {
  filter: brightness(50%);
}

.container-media-links li a:hover > .media-text {
  opacity: 1;
}

@media screen and (max-width: 599px) {
  .container-media-links li {
    padding-top: 100%;
  }
}
