footer {
  width: 100%;
  background-color: var(--color1);
  color: var(--font-color3);
  padding: 4vw;
}

.container-footer {
  width: 100%;
  min-height: 300px;
  height: auto;
  display: flex;
}

footer h5 {
  font-weight: 800;
  font-style: italic;
  font-size: 1.8rem;
  transform: scale(1, 0.8);
}

.footer-logo {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo p {
  color: var(--font-color2);
  font-size: max(14px, 0.8vw);
}

footer ul {
  width: 75%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

footer ul li {
  flex: 1 1 150px;
  display: flex;
  flex-direction: column;
}

footer ul li > * {
  padding-bottom: 16px;
}

footer ul li p {
  font-size: max(14px, 0.8vw);
}

footer ul li a,
footer ul li span {
  font-weight: 500;
  font-size: max(14px, 0.8vw);
  color: var(--font-color2);
  text-transform: uppercase;
  cursor: pointer;
}

.container-social-links a {
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
}

.container-social-links svg {
  transform: scale(1.2);
}

@media screen and (max-width: 800px) {
  .container-footer {
    flex-direction: column;
  }

  footer ul {
    width: 100%;
  }

  .footer-logo {
    width: 100%;
    padding-bottom: 40px;
  }

  footer li {
    padding-bottom: 40px;
  }
}
